import React from 'react';
import { Link } from "react-router-dom";

const NavBar = () => {

    return (
        <div>
            <img src="logo.jpg" alt="logo" />
            <ul>
                <li>
                    <Link to="/">Home</Link>
                </li>
                <li>
                    <Link to="/Quiz">Quiz</Link>
                </li>
            </ul>
        </div>
    );
}

export default NavBar;
