

import axios from 'axios';

class AdviceService {

    getRandomAdvice() {
        return axios.get(`https://api.adviceslip.com/advice`);
    }
}

export default (new AdviceService()); // singleton object