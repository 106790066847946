import { useEffect, useState } from "react";
import WeatherService from '../api/weather';
import FootballService from '../api/football';
import BinanceService from '../api/binance';
import AdviceService from '../api/advice';
import dayjs from "dayjs";
require('dayjs/locale/fr')
dayjs.locale('fr') // use locale globally

function Home() {

    const [ weather, setWeather ] = useState(0);
    const [ matches, setMatches ] = useState([]);
    const [ xrpPrice, setXrpPrice ] = useState(0);
    const [ advice, setAdvice ] = useState('');


    const getXRPPrice = () => {
        BinanceService.getXRPPrice().then(response => {
            setXrpPrice(response.data.price)
        }).catch(error => {
            console.error(error);
        })
    }

    const getRandomAdvice = () => {
        AdviceService.getRandomAdvice().then(response => {
            setAdvice(response.data.slip.advice);
        }).catch(error => {
            console.error(error);
        })
    }

    useEffect(() => {
        // WeatherService
        WeatherService.getCurrentWeather().then(response => {
            setWeather(Math.floor(response.data.main.temp))
        }).catch(error => {
            console.error(error);
        })
        // FootballService
        FootballService.getNextMatches().then(response => {
            setMatches([...response.data.matches.slice(0, 3)])
        }).catch(error => {
            console.error(error);
        })
        // AdviceService
        getRandomAdvice()
        //BinanceService
        getXRPPrice();
    }, [])

    return (
        <div>
            <h1>Salut Chef Scaletta !</h1>
            <div>Meteo: { weather } °C</div>
            <div>
                <h4>Next Matches</h4>
                <ul>
                    {
                        matches.map((match, index) => (
                            <li
                                key={ index }
                            >
                                { match.homeTeam.name } -  { match.awayTeam.name } { dayjs(match.utcDate).format('dddd HH:mm D/MM/YYYY') }
                            </li>
                        ))
                    }
                </ul>
            </div>
            <div>
                <div>XRP = { xrpPrice }</div>
                <button type="button" onClick={getXRPPrice}>refresh</button>
            </div>
            <div>
                Conseil:
                { advice }
                <button type="button" onClick={getRandomAdvice}>refresh</button>
            </div>
        </div>
    );
}

export default Home;
