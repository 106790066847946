import { useEffect } from 'react';
import data from '../api/json/italy.json';
import { useState } from "react";


const Content = ({ propositions, correctAnswer }) => {

    const [ show, toggleShow ] = useState(false);


    return (
        <>
            <ol>
                {
                    propositions.map((rep, index) => (
                        <li key={ index }>{ rep }</li>
                    ))
                }
            </ol>
            <button type="button" onClick={ () => toggleShow(!show) }>{ show ? 'hide' : 'show' }</button>
            {
                show && <span>{ correctAnswer }</span>
            }
        </>
    )
}


const Quiz = () => {

    const [ quiz ] = useState(data.quizz.fr.confirmé);

    useEffect(() => {
        console.log(data);
    })

    return (
        <ul>
            {
                quiz.map((item, index) => (
                    <li key={ index }>
                        <div>{ item.question }</div>
                        <Content propositions={ item.propositions } correctAnswer={ item.réponse } />
                    </li>
                ))
            }
        </ul>
    )
}

export default Quiz;