
import axios from 'axios';

class BinanceService {

    getXRPPrice() {
        return axios.get(`https://api3.binance.com/api/v3/avgPrice?symbol=XRPEUR`);
    }
}

export default (new BinanceService()); // singleton object