import React from "react";
import NavBar from './components/NavBar';
import Home from './components/Home';
import { Route, Switch } from "react-router-dom";
import Quiz from "./components/Quiz";

const App = () => {

    return (
        <div>
            <NavBar/>
            <div>
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route path="/home" component={Home}/>
                    <Route path="/quiz" component={Quiz} />
                </Switch>
            </div>
        </div>
    )
}

export default App;
